import { Add } from '@material-ui/icons';
import Decimal from 'decimal.js';
import { stringify } from 'query-string';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  Button,
  DateField,
  ExportButton,
  FunctionField,
  Identifier,
  Link,
  List,
  ListProps,
  NumberField,
  Pagination,
  PaginationProps,
  Record,
  TextField,
  TopToolbar,
  useListContext,
} from 'react-admin';
import { useLocation } from 'react-router-dom';
import { ISupplierPayoutPreferences } from './ISupplierPayoutPreferences';
import { PayoutOrderFilterSidebar } from './PayoutOrderFilterSidebar';
import { SupplierOrdersDatagrid } from './SupplierOrdersDatagrid';

type PostBulkActionButtonsProps = {
  amount: number;
  invoiceItemsIds: Identifier[];
  supplierId: string;
  // eslint-disable-next-line react/require-default-props
  preferences?: ISupplierPayoutPreferences;
};

const PostBulkActionButtons = ({ amount, invoiceItemsIds, supplierId, preferences }: PostBulkActionButtonsProps) => (
  <Button
    label={`Create payout for amount $${amount}`}
    component={Link}
    to={{
      pathname: '/payouts/create',
      state: { record: { amount, invoiceItemsIds, supplierId, preferences } },
    }}
  >
    <Add />
  </Button>
);

const PostPagination = (props: PaginationProps) => (
  <Pagination rowsPerPageOptions={[5, 10, 25, 50, 100, 500]} {...props} />
);

const ListActions = () => {
  const { total, loading } = useListContext();
  return (
    <TopToolbar>
      <ExportButton disabled={loading || total === 0} maxResults={10_000} />
    </TopToolbar>
  );
};

export const PayoutSupplierOrderList: FC<ListProps> = (props) => {
  const { search, state } = useLocation<{ preferences?: ISupplierPayoutPreferences; supplierName?: string }>();
  const parsedFilter = useMemo(() => {
    const filter = new URLSearchParams(search).get('filter');
    return filter && JSON.parse(filter);
  }, [search]);

  const [supplierName, setSupplierName] = useState(state?.supplierName);
  const [preferences, setPreference] = useState(state?.preferences);
  const [totalAmount, setTotalAmount] = useState(0);
  const [invoiceItemsIds, setInvoiceItemsIds] = useState<Identifier[]>([]);
  const [totalDueNotPaid, setTotalDueNotPaid] = useState(0);

  const handleSelectedDataChange = useCallback((data: Record[]) => {
    setTotalAmount(
      +data.reduce((totalDue: Decimal, d: Record) => totalDue.plus(d.amountDue || 0), new Decimal(0)).toFixed(2),
    );
    setInvoiceItemsIds(data.map((d: Record) => d.id));
  }, []);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BACKEND_URL as string}/api/admin/payouts/suppliers/${parsedFilter.supplierId}`, {
      method: 'GET',
      headers: new Headers({
        authorization: `Bearer ${localStorage.getItem('token')}`,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setTotalDueNotPaid(data.totalNotPaidOut);
        setSupplierName(data.supplierName);
        setPreference(data.payoutPreferences);
      })
      .catch((err) => {
        setTotalDueNotPaid(0);
        // eslint-disable-next-line no-console
        console.error(err);
      });
  }, [parsedFilter]);

  return (
    <List
      bulkActionButtons={
        <PostBulkActionButtons
          amount={totalAmount}
          invoiceItemsIds={invoiceItemsIds}
          supplierId={parsedFilter && parsedFilter.supplierId}
          preferences={preferences}
        />
      }
      aside={<PayoutOrderFilterSidebar />}
      title={`Select orders to create payout ${supplierName ? `for ${supplierName}` : ''}`}
      pagination={<PostPagination />}
      actions={<ListActions />}
      {...props}
    >
      <SupplierOrdersDatagrid onSelectedDataChange={handleSelectedDataChange} totalDueNotPaid={totalDueNotPaid}>
        <FunctionField
          label="Name"
          sortBy="name"
          render={(record: any) => (
            <Link
              to={{
                pathname: '/orders',
                search: stringify({
                  filter: JSON.stringify({ q: record.retailerOrderId }),
                }),
              }}
            >
              {record.name}
            </Link>
          )}
        />
        <TextField label="Supplier name" source="supplierName" />
        <TextField label="Retailer name" source="retailerName" />
        <TextField label="Retailer purchase order name" source="retailerPurchaseOrderName" />
        <DateField label="Order creation date" source="date" />
        <DateField label="Order fulfilled date" source="orderFulfilledDate" />
        <NumberField label="Line items total" source="lineItemTotal" options={{ style: 'currency', currency: 'USD' }} />
        <NumberField label="Shipping total" source="shippingTotal" options={{ style: 'currency', currency: 'USD' }} />
        <NumberField
          label="Handling fee total"
          source="handlingFeeTotal"
          options={{ style: 'currency', currency: 'USD' }}
        />
        <NumberField label="Crowdship fee, %" source="crowdshipFeePercent" />
        <NumberField
          label="Crowdship fee, $"
          source="crowdshipFeeAmount"
          options={{ style: 'currency', currency: 'USD' }}
        />
        <NumberField label="Manual Refunds" source="refundedTotal" options={{ style: 'currency', currency: 'USD' }} />
        <NumberField label="Amount due" source="amountDue" options={{ style: 'currency', currency: 'USD' }} />
      </SupplierOrdersDatagrid>
    </List>
  );
};
